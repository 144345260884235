/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'src/styles/global.css'

import { wrapWithProvider } from 'src/wrapWithProvider'

export const wrapRootElement = wrapWithProvider
