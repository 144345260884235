import { ResponsiveValue } from 'styled-system'

/**
 * Use this theme when you need to use theme values in css`` blocks.
 *
 * E.g.
 *
 * css`
 *  margin-top: ${theme.space}[1]
 * `
 *
 * This is the newer theme used by all styles using the `<Box />` -component.
 */
const theme = {
  breakpoints: {
    // This exists only in types, the way "styled-system" works is that any unknown breakpoint will
    // have no media queries so basically "min-width: 0px"
    // xs: '0px'
    sm: '720px',
    md: '920px',
    lg: '1280px',
  },

  space: [
    0,
    2,
    4,
    8,
    10, // not a power of two, but a one-off value
    12, // not a power of two, but a "half-note" (8 * 1.5)
    16,
    20, // not a power of two, but a one-off value
    24, // not a power of two, but a "half-note" (16 * 1.5)
    32,
    36,
    40, // not a power of two, but a one-off value
    48,
    64,
    92,
    120,
  ],

  fontSizes: [14, 16, 18, 20, 24, 28, 32, 40, 56],

  fontWeights: [400, 500, 600, 700],

  lineHeights: ['110%', '120%', '130%', '140%', '150%', '20px'],

  colors: {
    podcastBlue: '#4B69B1',
    podcastDark: '#0C0F13',
    podcastBeige: '#E1D9D2',
    podcastOrange: '#F5887B',
    lightPodcastBeige: 'rgba(225, 217, 210, 0.3)',
    hoverGray: '#9e9fa1',
    purple: '#6252E2',
    transparent: 'transparent',
    white: '#fff',
    gray50: '#F4F4F4',
    gray100: '#E5E5E5',
    gray200: '#E1E5EE',
    black50: '#F2F3F4',
    black100: '#D9DCDE',
    black200: '#BFC3C8',
    black300: '#A4AAB3',
    black400: '#898F9E',
    black500: '#6E7489',
    black600: '#595F72',
    black700: '#454A5B',
    black800: '#313642',
    black900: '#1E212A',
    black: '#000',
    inherit: 'inherit',
  },

  fonts: {
    inherit: 'inherit',
    Monospace:
      "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;",
    FactorA:
      "'Factor A', Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    Inter:
      "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },

  letterSpacings: ['-0.01em', '-0.02em'],

  sizes: {
    '100vw': '100vw',
    '100%': '100%',
    full: 1348,
    homeTop: 1200,
    homeBottom: 1048,
    rightSidebar: 400,
    episode: 1256,
  },

  borderWidths: [1],

  radii: [4, 8, 12, 16, 24, 32, 9999],

  shadows: {
    small:
      'rgba(0, 0, 0, 0.05) 0px 8px 24px, rgba(23, 23, 26, 0.05) 0px 12px 24px',
    normal:
      '0px 13px 45px rgba(0, 0, 0, 0.05), 0px 17px 45px rgba(23, 23, 26, 0.05)',
    strong:
      '0px 13px 45px rgba(0, 0, 0, 0.1), 0px 17px 45px rgba(23, 23, 26, 0.12)',
  },
} as const

type Theme = typeof theme
type ValueOf<T> = T[keyof T]

type FontVariant = {
  fontFamily: ValueOf<Theme['fonts']>
  fontSize?: ResponsiveValue<Theme['fontSizes'][number]>
  marginBottom?: Theme['space'][number]
  lineHeight?: ResponsiveValue<Theme['lineHeights'][number]>
  fontWeight: Theme['fontWeights'][number]
  letterSpacing?: ResponsiveValue<Theme['letterSpacings'][number]>
}

export const fontVariants: Record<string, FontVariant> = {
  h1: {
    fontFamily: theme.fonts.FactorA,
    // styled-system variants don't support object syntax
    fontSize: [32, null, 40],
    lineHeight: '120%',
    fontWeight: 700,
    // Fight global styles
    marginBottom: 0,
  },
  h2: {
    fontFamily: theme.fonts.FactorA,
    // styled-system variants don't support object syntax
    fontSize: [24, null, 32],
    lineHeight: '120%',
    fontWeight: 700,
    // Fight global styles
    marginBottom: 0,
  },
  h3: {
    fontFamily: theme.fonts.FactorA,
    fontSize: [28, null, 32],
    lineHeight: '120%',
    fontWeight: 500,
    // Fight global styles
    marginBottom: 0,
  },
  h4: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 24,
    lineHeight: '120%',
    fontWeight: 500,
    // Fight global styles
    marginBottom: 0,
  },
  h5: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 20,
    lineHeight: '130%',
    fontWeight: 700,
    // Fight global styles
    marginBottom: 0,
  },
  h6: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 18,
    lineHeight: '120%',
    fontWeight: 700,
    // Fight global styles
    marginBottom: 0,
  },
  large: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 20,
    lineHeight: '150%',
    fontWeight: 400,
  },
  medium: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 18,
    lineHeight: '140%',
    fontWeight: 400,
  },
  small: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 16,
    lineHeight: '140%',
    fontWeight: 400,
  },
  textLabel: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 16,
    lineHeight: '120%',
    fontWeight: 500,
  },
  menuLink: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 16,
    lineHeight: '120%',
    fontWeight: 700,
  },
}

/**
 * This export is used for compatibility with styled-system.
 * We don't utilize their theme functionality for most things, but rather just
 * limit the styles that our `<Box />` component can take in TypeScript.
 *
 * See stylePropTypes.ts for the types.
 *
 * Here's a few sources of information on the theme specification for the technically inclined:
 *
 * @see https://styled-system.com/theme-specification/
 * @see https://github.com/primer/components/blob/main/src/theme-preval.js
 * @see https://styled-system.com/guides/why-powers-of-two/
 * @see https://github.com/styled-system/styled-system/blob/master/packages/space/src/index.js#L4
 * @see https://www.figma.com/file/2YJF2uNmWmngamPIE9rsXI/Swarmia-Design-System?node-id=945%3A505
 * @see https://www.figma.com/file/lYkeZmzZO720ydXNVeyq8V/Swarmia-Colors?node-id=0%3A1
 * @see node_modules/@types/styled-system/index.d.ts#Theme
 */
export const styledSystemTheme = {
  // Styled system breakpoints need to be also an array
  breakpoints: Object.assign(
    Object.values(theme.breakpoints),
    theme.breakpoints,
  ),

  fonts: theme.fonts,
  colors: theme.colors,
  shadows: theme.shadows,
  sizes: theme.sizes,
  letterSpacings: theme.letterSpacings,
  borderWidths: {},

  // We don't use the styled-system scale for these, but use empty values
  // to prevent styled system from overriding these with defaults.
  space: [],
} as const

export default theme
